$primary-color: #ececec;
$primary-dark-color: #b8b8b8;
$accent-color: #ff4444;
$panel-dark-color: #272727;
$panel-color: #373737;
$panel-light-color: #474747;
$body-color: #575757;
$panel-lighter-color: #727272;
$panel-lightest-color: #c1c1c1;
$pickstop-color: #ffb300;
$light-gray: #b9b8b8;

$success-color: #5ddb65;
$point-color: #4bc57b;
$error-color: #ff4444;
$correct-color: #57b27b;
$incorrect-color: #9d4a4a;

$fade-top-color: #ffb300;
$fade-bottom-color: #ff4444;

$discord-color: #7288d9;
$discord-light-color: #8ba3fc;
$patreon-color: #ff424e;

$first-place-color: #ececec;
$other-places-color: #575757;
$first-place-font: #373737;
$other-places-font: #ececec;

$white: #FFFFFF;
$diamond: #666E82;
$diamond_light: #E1E9FF;
$platinum: #587485;
$platinum_light: #8BCAFF;
$gold: #85745B;
$gold_light: #EFCF66;
$silver: #676767;
$silver_light: #B9B8B8;
$bronze: #786057;
$bronze_light: #E6A780;
$wood: #806346;
$wood_light: #DE9343;

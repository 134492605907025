@import "styles/Colors.scss";

@keyframes flash {
	from {
		color: $point-color;
	}
	to {
		color: $pickstop-color;
	}
}

.left-pane {
	height: 100%;
	text-align: center;
	padding-left: 8px;

	.event-image-container img {
		width: 200px;
		margin-bottom: 8px;
	}

	.event {
		text-align: left;
		margin-top: 8px;
	}

	.event-format {
		h4 {
			letter-spacing: 2.8px;
			margin-right: 24px;
		}
	}

	.event-selector {
		div {
			display: inline-block;
		}
	}

	.event-toggle {
		position: relative;
		top: 36px;
		width: 24px;
		margin: 18px;
		float: right;

		span {
			cursor: pointer;
			background-color: $body-color;
			padding: 8px 2px;
			height: 100%;
			display: block;
			border: 1px solid $primary-color;
			border-radius: 12px;

			svg {
				stroke: $primary-color;
			}
		}
	}

	hr {
		border: 1px solid $primary-color;
	}

	h3 {
		font: normal normal normal 18px Roboto;
		letter-spacing: 3.6px;
		text-transform: uppercase;
	}

	h4 {
		text-align: left;
		margin-top: 8px;
		font: normal normal normal 14px Roboto;
		letter-spacing: 2.4px;
		text-transform: uppercase;
	}

	h5 {
		font: normal normal normal 14px Roboto;
		letter-spacing: 2.8px;
		text-transform: uppercase;
	}

	.shift-left {
		font: normal normal normal 14px Roboto;
		letter-spacing: 2.8px;
		text-transform: uppercase;
		margin-left: 26px;
		margin-right: 26px;
	}

	.brackets-submitted {
		margin-top: 15px;
		font-weight: bold;
		font-size: 12px;

		&.changed {
			span {
				animation: flash 0.5s;
			}
		}

		span {
			color: $pickstop-color;
		}
	}

	label {
		display: block;
		text-transform: uppercase;
		letter-spacing: 2.2px;
		font: normal normal normal 12px Roboto;
	}

	table {
		text-align: left;
	}
}

@import "styles/Colors.scss";

.group-stage-team-container {
	display: flex;
	justify-content: space-around;
	text-align: center;

	.info-toggle {
		position: absolute;
		z-index: 10;
		color: $light-gray;
		font-family: Roboto, Helvetica, sans-serif;
		top: 0px;
		right: 0px;
		width: 21px;
		height: 21px;
		background: $panel-color 0% 0% no-repeat padding-box;
		border-radius: 0px 0px 0px 12px;
		opacity: 1;

		svg {
			position: relative;
			top: 3px;
			right: -1px;
		}

		&:hover {
			color: $panel-color;
			background: $pickstop-color 0% 0% no-repeat padding-box;
			cursor: pointer;
		}

		&.disabled {
			display: none;
		}

		&.eliminated {
			border: none;
		}
	}

	.group-stage-team-players {
		box-sizing: border-box;
		position: relative;
		width: 90px;
		height: 90px;
		background: $panel-lighter-color 0% 0% no-repeat padding-box;
		border: 1px solid $primary-color;
		border-radius: 12px;
		opacity: 1;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		&.eliminated {
			border: none;
		}

		&.disabled {
			background: $primary-color 0% 0% no-repeat padding-box;
			opacity: 0.2;

			label {
				color: $panel-color;
				opacity: 1;
			}
		}

		table {
			margin-top: 15px;
			text-align: left;
			font: normal normal normal 10px/15px Roboto;
			letter-spacing: 0.5px;
			color: $pickstop-color;
			opacity: 1;
			border-style: hidden;
			width: 100%;
			height: auto;
			padding: 0px;
			white-space: nowrap;

			tr {
				border-bottom: 1px solid $pickstop-color;

				&:last-child {
					border-bottom: hidden;
				}
			}

			img {
				display: block;
				width: 7px;
				height: 5px;
				opacity: 1;
				margin: 0px auto;
			}

			label {
				text-align: center;
				font: normal normal normal 10px/15px Roboto;
				letter-spacing: 0.5px;
				color: $pickstop-color;
				opacity: 1;
				margin: 0px auto;
				margin-left: 2px;
				max-width: 73px;
				height: 13px;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	.group-stage-team {
		box-sizing: border-box;
		width: 90px;
		height: 90px;
		background: $panel-lighter-color 0% 0% no-repeat padding-box;
		border-radius: 12px;
		opacity: 1;
		color: $primary-color;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		.team-wrapper {
			height: 90px;
			width: 90px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&.faded {
				opacity: 0.3;
			}
		}

		.logo-wrapper {
			margin-bottom: 6px;
		}

		img {
			display: block;
			margin: auto;
			top: 8px;
			max-width: 50px;
			max-height: 50px;
			opacity: 1;
		}

		label {
			margin: 0px auto;
			font: normal normal normal 13px/14px Roboto;
			letter-spacing: 0.65px;
			opacity: 1;
			display: block;
			width: 80%;

			&:hover {
				cursor: pointer;
			}
		}

		&:hover {
			box-shadow: inset 0px 0px 3px 3px $primary-color;
			opacity: 1;
			cursor: pointer;
		}

		&:hover.locked {
			box-shadow: none;
		}

		&.advancing {
			background: $primary-dark-color 0% 0% no-repeat padding-box;
			color: $panel-color;
			border: 1px solid $primary-color;

			&.correct {
				background: linear-gradient(
					$primary-dark-color,
					$primary-dark-color,
					$point-color
				);
			}
		}

		&.eliminated {
			background: $panel-lighter-color 0% 0% no-repeat padding-box;
			border-color: $panel-lighter-color;
			color: $primary-color;
			border: 1px solid $panel-color;

			&.correct {
				background: linear-gradient($body-color, $body-color, $point-color);
			}
		}

		&.disabled {
			background: $primary-color 0% 0% no-repeat padding-box;
			opacity: 0.2;

			img {
				opacity: 0.1;
			}

			label {
				color: $panel-color;
				opacity: 1;
			}
		}
	}
}

@import "styles/Colors.scss";

.custom-modal {
	z-index: 1500;
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	overflow: scroll;

	.overlay {
		position: fixed;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba($panel-color, 0.8);
		backdrop-filter: blur(5px);
		overflow: scroll;

		.overlay-inner {
			display: flex;
			min-width: 100%;
			min-height: 100%;
		}

		.modal-content {
			margin: auto;
			background-color: transparent;
			border: 0px;
		}

		.modal-header {
			width: auto;
			font-weight: bold;
			border-bottom: 0px;
			text-align: center;
			font: normal normal bold 18px/14px Roboto;
			letter-spacing: 0.9px;

			h3 {
				color: $pickstop-color;
			}

			.close {
				color: $primary-color;
				float: right;
				position: relative;
				top: -16px;
				right: -8px;
			}
		}

		.modal-icon-header {
			width: auto;
			font-weight: bold;
			border-bottom: 0px;
			text-align: center;
			font: normal normal bold 18px/14px Roboto;
			letter-spacing: 0.9px;

			.MuiBox-root {
				text-align: left;

				label {
					text-align: left;
					position: relative;
					left: -12px;
					top: -2px;
					font-size: 12px;
					font: normal normal normal 12px/27px Arial;
					letter-spacing: 1.2px;
					color: $pickstop-color;

					svg {
						position: relative;
						top: 4px;
						height: 16px;
						stroke: $pickstop-color;
						fill: $pickstop-color;
					}
				}

				.close {
					color: $primary-color;
					float: right;
					position: relative;
					right: -12px;
				}
			}
		}

		.modal-body {
			padding: 0.5rem 1rem;
		}

		.modal-content {
			width: auto;
		}

		.modal-footer {
			border-top: 1px solid $primary-color;
		}

		.modal-panel {
			background-color: $panel-color;
			max-width: 1200px;
			border-radius: 12px;
			border: 1px solid $primary-color;
			margin: auto;
		}

		.modal-divider {
			border-top: 1px solid $primary-color;
			margin: 0px;
		}

		.buttons {
			label {
				min-width: 20px;
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
			}

			svg {
				stroke: $primary-color;
				fill: $primary-color;
				margin: 8px;
				cursor: pointer;
				max-height: 16px;
				max-width: 16px;
				display: inline-block;
			}
		}
	}
}

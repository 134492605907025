@import "styles/Colors.scss";

.event-creator {
	select:not(.react-datetime-picker__inputGroup__amPm) {
		display: block;
		margin-top: 4px;
	}
	label {
		display: block;
	}

	img {
		display: block;
	}

	.react-calendar {
		color: $panel-color;

		button {
			color: $panel-color;
		}

		.react-calendar__month-view__days__day--neighboringMonth {
			color: $primary-color;
		}

		.react-calendar__month-view__days__day:disabled {
			color: $primary-color;
			background-color: transparent;
			border: 0px;
		}
	}
}

@import "styles/Colors.scss";

@media (max-width: 450px) {
	.team-selection-modal {
		width: auto !important;
		.teams {
			.win-vs {
				width: 120px !important;
			}
		}

		.prediction-label {
			label {
				width: 372px !important;
			}
		}
		.score-selection {
			.predictions {
				width: 380px !important;
				div {
					&.bestof5 {
						margin-right: 0px !important;
					}
					&.bestof7event {
						margin-right: 0px !important;
					}
				}
			}
		}
	}
}

@media (max-height: 600px) {
	.team-selection-modal {
		button {
			margin-bottom: 8px !important;
		}
	}
}

.team-selection-modal {
	width: 510px;
	margin: auto;

	button {
		margin-bottom: 46px;

		svg {
			stroke: $primary-color;
			height: 15px;
			position: relative;
			top: 2px;
		}
	}

	.teams {
		margin-bottom: 8px;
		display: flex;

		div {
			display: inline-block;
			vertical-align: top;
		}

		.team-details {
			height: calc(100% - 24px);
			width: 120px;
			text-align: center;

			.team-info {
				position: relative;
				height: 120px;
				width: 120px;
				border-radius: 12px;
				color: $primary-color;
				background-color: $panel-lighter-color;
				border: 1px solid $primary-color;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				&.selected {
					background-color: $primary-color;
					color: $panel-color;
				}

				img {
					margin-top: 8px;
					max-height: 60px;
					max-width: 100px;
				}

				.team-name {
					margin-top: 8px;
				}
			}

			.team-players {
				background-color: $panel-color;
				font-size: 12px;
				border-radius: 12px;
				width: 120px;
				position: relative;
				top: -24px;
				padding-top: 24px;
				z-index: 5;
				max-height: 112px;
				overflow-y: auto;
				overflow-x: clip;

				table {
					margin: 6px auto;
					td,
					th {
						border: 1px solid $primary-color;
					}
					tr:first-child td {
						border-top: 0;
					}
					tr:last-child td {
						border-bottom: 0;
					}
					tr td:first-child,
					tr th:first-child {
						border-left: 0;
					}
					tr td:last-child,
					tr th:last-child {
						border-right: 0;
						text-align: left;
						padding: 2px 4px;
					}

					img {
						max-width: 10px;
						max-height: 10px;
						margin-right: 4px;
					}
					label {
						margin-bottom: 0px;
						text-overflow: ellipsis;
						overflow: hidden;
						display: flex;
					}
				}
			}
		}

		.win-vs {
			width: 270px;
			height: 220px;
			text-align: center;
			text-transform: uppercase;

			div {
				display: flex;
				margin: auto;
				margin-top: 44px;

				label {
					width: 100px;
					height: 27px;
					background-color: $panel-color;
					border: 1px solid $primary-color;
					border-radius: 12px;
					display: block;
					margin: auto;
					padding-top: 8px;
				}
			}
		}
	}

	.prediction-label {
		text-align: center;
		label {
			width: 100%;
			display: block;
			padding-top: 8px;
			text-align: center;
			font-size: 23px;
			text-transform: uppercase;
			background-color: $panel-color;
			border: 2px solid $primary-color;
			border-radius: 25px;
			height: 35px;
			margin-bottom: 8px;
		}
	}

	.score-selection {
		.predictions {
			display: flex;
			div {
				width: 120px;
				min-height: 120px;
				border-radius: 12px;
				text-align: center;
				display: inline-block;
				margin: auto;
				cursor: pointer;
				color: $panel-color;
				font-size: 14px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				label {
					margin-bottom: 0px;
					cursor: pointer;
				}

				img {
					margin-top: 8px;
					max-width: 100px;
					max-height: 60px;
				}

				&.bestof5 {
					margin-right: 75px;
				}

				&.bestof7odd {
					margin-right: 10px;
				}

				&.bestof7even {
					margin-right: 10px;
				}

				&.easy-win {
					background-color: $primary-color;
				}

				&.easy-normal-win {
					background-image: linear-gradient($primary-color, #ff851c);
				}

				&.normal-win {
					background-image: linear-gradient($primary-color, #ff9100);
				}

				&.normal-tough-win {
					background-image: linear-gradient($primary-color, #f07b30);
				}

				&.tough-win {
					background-image: linear-gradient($primary-color, #e6584e);
				}
			}
		}
	}
}

@import "styles/Colors.scss";

/*
xs < 576px
sm 576px < 768px
md 768px < 992px
lg 992px < 1200px
xl 1200px < more
*/

@media (max-width: 991.98px) {
	.reset-password-screen {
		.reset-password-form {
			margin: auto;
		}
	}
}

.reset-password-screen {
	width: 100%;
	height: 100%;
	background-color: $body-color;
	.reset-password-form {
		width: 500px;
		border-radius: 16px;
		background-color: $panel-color;
		border: 1px solid $primary-color;
		align-items: center;
		padding-top: 8px;

		h4 {
			margin-left: 60px;
		}

		table {
			margin-left: 12px;
			margin-top: 12px;

			label {
				text-align: right;

				&.error {
					color: $error-color;
				}
			}
		}

		label {
			display: block;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
		}

		.input-icon {
			position: absolute;
			margin-left: 150px;
			width: 18px;
			margin-top: 11px;
		}

		.submit-button {
			display: inline-block;
			margin-left: 12px;
		}

		a {
			text-decoration: underline;
			font-size: 14px;
		}

		.font-center {
			text-align: center;
		}

		.external-providers {
			height: 50px;
			margin-bottom: 12px;
			div {
				display: inline-block;
				width: 50%;
				img {
					margin: auto;
					display: block;
				}
			}
		}

		button {
			&.create-account {
				width: 140px;
				height: 24px;
				border-radius: 12px;
				background-color: $primary-color;
				border: 1px solid $primary-color;
				line-height: 1px;
				display: block;
				margin: auto;
				font-size: 14px;
				color: $panel-color;
				font-weight: bold;
			}
		}

		input {
			width: 172px;
			height: 35px;
			border-radius: 18px;
			background-color: $panel-color;
			border: 2px solid $primary-color;
			padding-left: 16px;
			margin-bottom: 12px;
			display: block;

			&::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $primary-color;
				font-size: 14px;
				opacity: 0.5; /* Firefox */
			}
		}

		hr {
			border-top: 1px solid $primary-color;
			margin-top: 8px;
			margin-bottom: 4px;
		}
	}

	.logo {
		width: 200px;
		height: 51px;
		margin: 24px auto;
		display: block;
	}

	h1 {
		margin: auto;
		font-size: 42px;
	}
}

@import "styles/Colors.scss";

.score-pill {
	.inner {
		border: 1px solid $primary-color;
		border-radius: 12px;
		margin: auto;
		background: $panel-color;
		cursor: pointer;
		padding-bottom: 12px;

		&:hover {
			.score-label {
				svg {
					fill: $pickstop-color;
				}
			}
		}

		.score-label {
			display: inline-block;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			text-align: center;
			width: 72px;
			position: relative;
			top: 5px;
			left: 2px;

			svg {
				width: 16px;
				height: 16px;
				position: relative;
				top: 2px;
			}
		}

		.more-details {
			width: 70%;
			height: 15px;
			clear: both;
			margin: 0px auto;
			font-size: 8px;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.5px;

			svg {
				width: 16px;
				stroke: $primary-color;
				margin: 1.5px;
			}
		}

		.corner {
			width: 70px;
			float: right;
			text-align: center;

			.points {
				font-size: 13px;
				font-weight: bold;
				background-color: $primary-color;
				color: $panel-color;
				border-left: 2px solid $primary-color;
				border-bottom: 2px solid $primary-color;
				border-radius: 0 8px 0 12px;
			}

			.top {
				font-size: 12px;
				margin-top: 1px;
				margin-left: 3px;
			}
		}
	}
}

@import "styles/Colors.scss";

/*
xs < 576px
sm 576px < 768px
md 768px < 992px
lg 992px < 1200px
xl 1200px < more
*/

@media (max-width: 991.98px) {
	.pickusername-screen {
		.pickusername-form {
			margin: auto;
		}
	}
}

.pickusername-screen {
	width: 100%;
	height: 100%;
	background-color: $body-color;
	.pickusername-form {
		width: 500px;
		border-radius: 16px;
		background-color: $panel-color;
		border: 1px solid $primary-color;
		align-items: center;
		padding-top: 8px;
		padding-bottom: 8px;

		h4 {
			font-size: 20px;
			text-align: center;
			margin-top: 6px;
			margin-bottom: 24px;
		}

		p.error {
			color: $error-color;
		}

		a {
			text-decoration: underline;
			font-size: 14px;
		}

		.font-center {
			text-align: center;
		}

		.provider-example {
			text-align: center;

			label {
				display: inline-block;
				margin-right: 8px;
			}
		}

		hr {
			border-top: 1px solid $primary-color;
			margin-top: 8px;
			margin-bottom: 4px;
		}
	}

	h3 {
		font-size: 20px;
		text-decoration: underline;
		margin-left: 34px;
		margin-top: 12px;
		margin-bottom: 0px;
	}

	.logo {
		width: 200px;
		height: 51px;
		margin: 24px auto;
		display: block;
	}

	h1 {
		margin: auto;
		font-size: 42px;
	}
}

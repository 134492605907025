@import "styles/Colors.scss";

body {
	background-color: $panel-color;
	height: 100vh;
	font-family: "Roboto", sans-serif;
	margin: 0px;

	#root {
		height: 100vh;
		color: $primary-color;

		.body {
			top: 48px;
			bottom: 0px;
			width: 100%;
			overflow: auto;
			position: absolute;
			vertical-align: top;
			background-color: $body-color;
			display: flex;
			align-items: flex-start;
			justify-content: center;

			&.sm {
				bottom: 62px;
			}

			&.notopbar {
				top: 0px;

				&.sm {
					bottom: 0px;
				}
			}
		}
	}

	a {
		color: #ffb300;

		.nav-link {
			color: #ffb300;
		}
	}
}

input {
	color: $primary-color;
	background-color: $panel-dark-color;
	padding: 6px;
	margin: 4px;
	border-style: double;

	&:focus {
		background-color: $panel-color;
		outline: none;
	}
}

button {
	text-transform: capitalize;
	color: $primary-color;
	background-color: $panel-color;
	padding: 8px 16px;
	border-radius: 8px;
	border: 1px solid $primary-color;

	&.btn-primary {
		color: $primary-color;
		background-color: $panel-color;
		border: 1px solid $primary-color;

		&:not(:disabled) {
			&:hover {
				background-color: $panel-light-color;
				border: 1px solid $primary-color;
				color: $primary-color;
			}

			&:focus {
				background-color: $panel-light-color;
				border: 1px solid $primary-color;
				color: $primary-color;
				outline: none !important;
				box-shadow: none !important;
			}

			&:active {
				background-color: $panel-color !important;
				border: 1px solid $primary-color !important;
				color: #fff !important;
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}

	&:hover {
		background-color: $panel-light-color;
	}

	&:disabled {
		color: $panel-light-color;
		background-color: $panel-dark-color;
		border: 1px solid $panel-color;
	}

	&.close {
		color: $primary-color;
	}
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $panel-color;
	z-index: 100;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary-color;
	border-radius: 8px;
	z-index: 100;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $error-color;
}

footer {
	position: absolute;
	bottom: 0px;
	width: 100%;

	div {
		display: inline-block;
	}

	a {
		color: $accent-color;
	}
}

.left-pane {
	position: relative;
	vertical-align: top;
	background-color: $panel-color;
	width: 350px;
	display: inline-block;
	z-index: 100;
	overflow: auto;
	padding-bottom: 50px;

	h4 {
		color: $pickstop-color;
	}

	h5 {
		font-size: 14px;
		letter-spacing: 2.8px;
		margin-left: 26px;
		margin-right: 26px;
	}

	.toggle {
		position: absolute;
		right: 0px;
		top: 0px;
		z-index: 101;
		cursor: pointer;
		width: 24px;
		height: 24px;
	}

	.event-image-container {
		position: relative;
		border: 2px solid $primary-color;
		border-radius: 12px;
		display: inline-block;
		margin-bottom: 8px;
		overflow: hidden;

		img {
			margin-bottom: 0px;
			display: flex;
			cursor: pointer;
		}
	}
}

label.selection-pill {
	display: inline-block;
	width: 120px;
	border-radius: 12px;
	margin-left: 15%;
	background-color: $body-color;
	border: 1px solid $primary-color;

	span {
		width: 36.5%;
		display: inline-block;
		text-align: center;
		padding: 4px 8px;
		border-radius: 12px;
		color: $panel-color;
		cursor: pointer;
		&.active {
			background-color: $primary-color;
		}
	}
}

.pickstop-color {
	color: $pickstop-color !important;
}

.modal-content {
	background-color: $panel-light-color;
}

html {
	font-size: 14px;
}
@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

fieldset {
	border: 2px groove $primary-color;
	padding: 8px;

	legend {
		display: block;
		width: auto;
		margin-left: 12px;
		margin-bottom: 2px;
		padding: 2px;
		font-size: 1.5rem;
		line-height: inherit;
		color: inherit;
		font-size: 16px;
		white-space: normal;
		margin-left: 12px;
		padding-left: 8px;
		padding-right: 8px;
	}
}

.__react_component_tooltip {
	opacity: 1;
	background-color: $panel-dark-color !important;
	color: $primary-color !important;
}

.Toastify__toast--success {
	background-color: $panel-dark-color !important;
	color: $primary-color !important;
}

.click-target {
	cursor: pointer;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(26px, -6px) scale(0.75) !important;
}

.MuiDivider-root {
	background-color: $primary-color !important;
}

input:-internal-autofill-selected {
	background-color: transparent !important;
}
@import "styles/Colors.scss";
.success-fail-modal {
    &.fail {
        color: $error-color;

        label {
            display: block;
            text-align: center;
            margin: 0px auto;
        }

        .modal-header {
            div {
                float: none !important;
            }

            display: block;
            position: relative;

            .close {
                display: block;
                position: relative;
                top: -8px;
                right: -8px;
                padding: 10px;
            }
        }

        .discord-container {
            display: block;
            margin: auto;

            .discord-button {
                margin: auto;
            }
        }
    }

    &.success {
        color: $success-color;

        .modal-header {
            position: relative;

            .close {
                position: relative;
                right: -16px;
                padding: 10px;
            }
        }
    }
}

@import "styles/Colors.scss";

.leaderboard-container {
	position: relative;
}

.right.events {
	min-height: 200px;
}

.leaderboard {
	.leaderboard-row {
		margin: 2px 0;
	}

	.leaderboard-headers {
		.leaderboard-row {
			color: $pickstop-color;
			font-size: 12px;

			.header-1 {
				display: inline-block;
				margin: 0 5px;
				width: 10px;
			}

			.header-2 {
				display: inline-block;
				margin: 0 5px;
				width: 145px;
				text-align: center;
			}

			.header-3 {
				display: inline-block;
				margin: 0 5px;
				width: 70px;
				text-align: center;
			}
		}
	}

	.leaderboard-body {
		.leaderboard-row {
			&.first {
				color: $pickstop-color;
			}
		}

		.position {
			display: inline-block;
			margin: 0 5px;
			width: 10px;
			vertical-align: middle;
			text-align: right;
			direction: rtl;
		}

		.name {
			cursor: pointer;
			display: inline-block;
			margin: 0 5px;
		}

		.score {
			display: inline-flex;
			margin: 0 5px;
			padding: 0 6px;
			border-radius: 12px;
			height: 33px;
			width: 58px;
			cursor: pointer;
			vertical-align: middle;
			background-color: $other-places-color;
			color: $other-places-font;

			align-content: center;
			justify-content: flex-end;
			align-items: center;

			img {
				display: inline-block;
				height: 30px;
				width: 30px;
			}

			&.first {
				background-color: $first-place-color;
				color: $first-place-font;
			}

			&.noclick {
				cursor: default;
			}

			label {
				font-size: 16px;
			}
		}

		&.highlight {
			color: $panel-color;
			background-image: linear-gradient(
				135deg,
				$primary-color,
				$pickstop-color
			);
		}
	}
}

.leaderboard.modal-table {
	width: 300px;

	.leaderboard-body {
		max-height: 325px;
		overflow-y: scroll;
		position: relative;

		.leaderboard-row {
			.score {
				justify-content: center;
			}
		}
	}

	.leaderboard-headers {
		position: relative;
		.leaderboard-row {
			.header-1 {
				width: 6px;
			}
		}
	}

	&.trophy {
		width: 400px;
		.goToTop {
			top: -18px;
		}
	}

	.goToTop {
		width: 13px;
		position: absolute;
		top: -3px;
		right: -3px;
		svg {
			fill: $pickstop-color;
			stroke: $pickstop-color;
		}
	}
	.goToBottom {
		color: $pickstop-color;
		float: right;
		position: relative;
		right: -6px;
		top: 3px;
	}
}

.current-user {
	.topTrophies {
		border-bottom: none;
	}
}

.leaderboardSearch {
	display: flex;
	margin-top: 45px;
	align-items: center;
	justify-content: center;
	svg {
		position: relative;
		left: 26px;
		height: 16px;
		width: 16px;
		fill: $primary-color;
	}
	input {
		border-radius: 12px;
		padding-left: 24px;
		color: $primary-color;
		border-color: $primary-color;
	}
	::placeholder {
		color: $primary-color;
		opacity: 0.5;
	}
}

.leaderboard-button-row {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	margin-bottom: 60px;

	&.hidden {
		display: none;
	}

	.leaderboard-button-container {
		margin-top: 5px;
		max-width: 50%;
		display: inline-block;

		.leaderboard-details {
			text-align: center;
			margin: 0 10px;
			position: relative;

			&.full {
				svg {
					position: relative;
					top: 2px;
					right: 2px;
				}
			}

			&.your {
				svg {
					position: relative;
					top: 1px;
					right: 4px;
				}
			}
		}
	}

	svg {
		stroke: $primary-color;
		fill: $primary-color;
	}
}

.leaderboard-heading-container {
	&.trophy {
		display: none;
	}
	&.events {
		position: absolute;
		left: -57px;
		top: 40%;
		color: $pickstop-color;
		fill: $pickstop-color;
		-webkit-transform: rotate(270deg); /* Chrome, Opera 15+, Safari 3.1+ */
		-moz-transform: rotate(270deg); /* Firefox 3.5+ */
		-ms-transform: rotate(270deg); /* IE 9 */
		-o-transform: rotate(270deg); /* Opera 10.5+ */
		transform: rotate(270deg); /* Firefox 16+, IE 10+, Opera */

		.leaderboard-heading {
			display: inline-block;
			text-align: center;

			h4 {
				display: inline-block;
			}

			svg {
				margin-right: 8px;
				vertical-align: middle;
				position: relative;
				top: -2px;
			}
		}

		.info {
			float: right;
			position: relative;
			top: 7px;
			right: 8px;
		}
	}
}

.leaderboard.trophy {
	max-width: 465px;

	.leaderboard-row {
		margin-left: 20px;
		.header-3 {
			width: 150px;
		}
	}

	.topTrophies {
		margin: 0 5px;
	}

	.awardImage {
		height: 30px;
		width: 30px;
		vertical-align: middle;
	}
}

label.total-entrants {
	display: none;
	&.trophy {
		display: block;
		margin-top: 5px;
		font-weight: bold;
		font-size: 12px;
		color: $pickstop-color;
		text-align: center;
	}
}

.brackets-submitted {
	&.trophy {
		display: none;
	}
}

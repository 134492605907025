@import "styles/Colors.scss";

.score-breakdown {
	margin-top: 4px;
	width: 250px;
	margin-bottom: 10px;

	.inner {
		width: 100%;
		border: 3px solid $primary-color;
		border-radius: 12px;
		background: $panel-color;
		margin: auto;
		letter-spacing: 0.5px;
		box-sizing: border-box;
		overflow: hidden;

		label {
			font-size: 14px;
			text-align: center;
			width: 100%;
			margin-top: 4px;
			margin-bottom: 0;

			&.score-header {
				text-transform: uppercase;
				color: $pickstop-color;
				font-weight: bold;
				letter-spacing: 0.5px;
				display: block;

				span:first-child {
					position: relative;
					top: -2px;
				}

				svg {
					color: $primary-color;
					margin-left: 17px;
					width: 14px;
					height: 14px;
					cursor: pointer;
					position: relative;
					top: 1px;

					&:hover {
						color: $pickstop-color;
					}
				}
			}
		}

		table {
			box-sizing: border-box;
			width: 101%;
			border-top: 1px solid $primary-color;
			border-bottom: 1px solid $primary-color;
			position: relative;
			left: -1px;
			border-collapse: collapse;
			line-height: 12px;

			tbody {
				box-sizing: border-box;
				border: 1px solid $primary-color;
				border-radius: 0px 12px 0px 12px;
			}
			thead {
				box-sizing: border-box;
				border-left: 1px solid $primary-color;
				border-right: 1px solid $primary-color;
			}

			th {
				padding-left: 8px;
			}

			tr {
				box-sizing: border-box;

				&:first-child {
					border-radius: 0px 12px 0p 0px;
				}

				&.stage-divider {
					text-transform: uppercase;
					color: $pickstop-color;
					font-size: 12px;
					height: 25px;
					display: flex;
					align-items: flex-end;
					background-color: $panel-color;
				}

				&.points {
					background-color: $panel-light-color;
					display: flex;
					align-items: center;
					border-bottom: solid 1px $primary-color;

					label {
						display: block;
						height: 20px;
						text-align: left;
					}

					span {
						color: $light-gray;
					}
				}

				&.bonus-points {
					background-color: $panel-color;
				}

				td {
					background-color: transparent;
					box-sizing: border-box;
				}

				td:first-child {
					width: 80%;
					display: inline-block;
					padding-left: 8px;
					font-size: 12px;
				}

				td:last-child {
					text-align: right;
					display: inline-block;
					width: 20%;
					padding-right: 8px;
					font-size: 18px;
				}

				&.total {
					color: $panel-color;
					font-size: 10px;
					font-weight: bold;

					td {
						width: 100%;
						text-align: center;

						&.total-label {
							color: $pickstop-color;
							padding: 2px 0px;
						}
					}
				}
			}
		}

		.score-summary label {
			width: 100%;
			display: block;
			text-align: center;
			font-size: 14px;
			margin-top: 0;
			&.total-points {
				font-size: 18px;
			}
		}

		.score-summary {
			border-radius: 0 0 12px 12px;
			&.wood {
				background-color: $wood;
			}
			&.bronze {
				background-color: $bronze;
			}
			&.silver {
				background-color: $silver;
			}
			&.gold {
				background-color: $gold;
			}
			&.platinum {
				background-color: $platinum;
			}
			&.diamond {
				background-color: $diamond;
			}
		}

		.hide-details {
			font-size: 8px;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			width: 100%;
			text-align: center;
			cursor: pointer;
			border-radius: 12px;

			&:hover {
				color: $panel-color;
				background-color: $primary-color;
			}

			svg {
				width: 16px;
				stroke: $primary-color;
				margin: 1.5px;
			}
		}
	}
}
